import React from 'react'
import { useState, useEffect } from 'react';
import './index.css'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import * as io from 'socket.io-client';
import socketConfig from '../../Socket/config.js'
import { PaginationControl } from 'react-bootstrap-pagination-control';
import socket from '../../socket';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginImg from '../../Images/LoginLogo12.png'

const lang = require('../../Config/lang.js');

const Cashier = (props) => {
  const [codeForm, setCodeForm] = useState({ code: '' });
  const [createtableName, setCreateTableName] = useState({ Name: '' });
  const [createtablePassword, setCreateTablePassword] = useState({ Password: '' });
  const [createtableMaxplayers, setCreateTableMaxplayers] = useState({ MaxPlayers: '2' });
  const [createtablePrivacy, setCreateTablePrivacy] = useState({ Privacy: 'public' });
  const [createtableMinBuyIn, setCreateTableMinBuyIn] = useState({ MinBuyIn: '' });
  const [createtableMaxBuyIn, setCreateTableMaxBuyIn] = useState({ MaxBuyIn: '' });
  const [createtableLimit, setCreateTableLimit] = useState({ limit: 'limit' });
  const [createtablesmallBlind, setCreateTablesmallBlind] = useState({ smallBlind: '1000' });
  const [createtablebigBlind, setCreateTablebigBlind] = useState({ bigBlind: '2000' });
  const userData = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true)
  const [depositePaymentMethod, setDepositePaymentMethod] = useState('manual');
  const [depositAmount, setDepositAmount] = useState('');
  const [isCardMethodSelected, setIsCardMethodSelected] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [depositeCard, setDepositeCard] = useState('visa');
  const [lockedBonusHistory, setLockedBonusHistory] = useState({});
  const [createTableReqHistory, setCreateTableReqHistory] = useState({})
  const [hourPackages, setHourPackages] = useState([])
  const [isActiveHistory, setIsActiveHistory] = useState({
    table: 'DepositHistory'
  })
  const [activeHistoryList, setActiveHistoryList] = useState({});
  const [page, setPage] = useState(1);
  const [isActive, setIsActive] = useState({
    table: props.activeTab
    //table: "tableCreate"
  })
  const [hourSubscription, setHourSubscription] = useState(props.hourSubscription);
  
  const [isActivePassword, setisActivePassword] = useState(false);

  useEffect(() => {
    const mobile = window.innerWidth <= 1024;
    setIsMobile(mobile);

    if (isActive == 'buyHour') {
      hourPackageListingReceived();
    }
  }, [window.innerWidth]);


  const claimCode = (id) => {
    const data = {
      playerId: userData.playerId,
      historyId: id,
    }
    socket.emit('claimCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        hideShowDiv('lockedBonus');
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }
  const reedemCode = () => {
    const data = {
      playerId: userData.playerId,
      code: codeForm.code
    }
    socket.emit('reedemCode', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCodeForm({
          code: ''
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCodeForm({
          code: ''
        });
      }
    });
  }
  
  const createTableReq = () => {
    const data = {
      playerId: userData.playerId,
      name: createtableName.Name,
      password: createtablePassword.Password,
      maxPlayers: createtableMaxplayers.MaxPlayers,
      privacy: createtablePrivacy.Privacy,
      minbuyin: createtableMinBuyIn.MinBuyIn,
      maxbuyin: createtableMaxBuyIn.MaxBuyIn,
      limit: 'no_limit',
      smallBlind: createtablesmallBlind.smallBlind,
      bigBlind: createtablebigBlind.bigBlind,
    }
    
    let name = createtableName.Name;
    if (name.length > 10) {
      toast.success('테이블 이름은 10자를초과 할 수 없습니다', {
          style: { width: '460px' },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
    }
    
    if (createtableMinBuyIn.MinBuyIn == "") {
      toast.success('최소 바이인을 입력해주세요', {
          style: { width: '460px' },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
    }
     
    if (createtableMaxBuyIn.MaxBuyIn == "") {
      toast.success('최대 바이인을 입력해주세요', {
          style: { width: '460px' },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
    }
    
    socket.emit('createTableReq', data, (res) => {
      if (res.status == 'success') {
        toast.success(<div>테이블 개설 요청이 접수되었습니다!<br />테이블 개설 진행 상황은 테이블 개설 리스트에서 확인 가능합니다.<br />개설된 테이블은 승인 후 24시간 동안 유지되며, 24시간 이후에 자동 삭제됩니다.<br />테이블 개설 요청은 심사 후에 거절될 수도 있습니다.</div>, {
          style: { width: '460px' },
          position: "top-center",
          autoClose: 30000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCreateTableName({
          Name: ''
        });
        setCreateTableMaxplayers({
          MaxPlayers: '2'
        });
        setCreateTablePrivacy({
          Privacy: 'public'
        });
        setCreateTablePassword({
          Password: ''
        });
        setCreateTableMinBuyIn({
          MinBuyIn: ''
        });
        setCreateTableMaxBuyIn({
          MaxBuyIn: ''
        });
        setCreateTableLimit({
          Limit: 'limit'
        });
        setCreateTablesmallBlind({
          smallBlind: '1000'
        });
        setCreateTablebigBlind({
          bigBlind: '2000'
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setCreateTableName({
          Name: ''
        });
        setCreateTableMaxplayers({
          MaxPlayers: '2'
        });
        setCreateTablePrivacy({
          Privacy: 'public'
        });
        setCreateTablePassword({
          Password: ''
        });
        setCreateTableMinBuyIn({
          MinBuyIn: ''
        });
        setCreateTableMaxBuyIn({
          MaxBuyIn: ''
        });
        setCreateTableLimit({
          Limit: 'limit'
        });
        setCreateTablesmallBlind({
          smallBlind: '1000'
        });
        setCreateTablebigBlind({
          bigBlind: '2000'
        });
      }
    });
    
    setTimeout(() => {
      
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('createTableReqHistory', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.createTableReqHistory);
          setCreateTableReqHistory(res.result.createTableReqHistory);
          
          
        } else {
          setCreateTableReqHistory({});
        }
      });
      
      
      
      setIsActive({
        table: 'tableCreateList',
      });
    }, 1000);
  }

  const handleChange = (event) => {
    setCodeForm({
      ...codeForm,
      [event.target.id]: event.target.value,
    });
  };

  const handleChangeTableName = (event) => {
    setCreateTableName({
      ...createtableName,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeTableMaxPlayers = (event) => {
    setCreateTableMaxplayers({
      ...createtableMaxplayers,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeTablePrivacy = (event) => {
    setCreateTablePrivacy({
      ...createtablePrivacy,
      [event.target.id]: event.target.value,
    });
    
    if(event.target.value == "private") {
      setisActivePassword(true);
      
    } else {
      setisActivePassword(false);
      
    }
  };
  
  const handleChangeTablePassword = (event) => {
    setCreateTablePassword({
      ...createtablePassword,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeMinBuyIn = (event) => {
    setCreateTableMinBuyIn({
      ...createtableMinBuyIn,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeMaxBuyIn = (event) => {
    setCreateTableMaxBuyIn({
      ...createtableMaxBuyIn,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeLimit = (event) => {
    setCreateTableLimit({
      ...createtableLimit,
      [event.target.id]: event.target.value,
    });
  };
  
  const handleChangeBlind = (event) => {
    var blind = event.target.value;
    var blindparts = blind.split("/");
    setCreateTablesmallBlind({
      ...createtablesmallBlind,
      'smallBlind': blindparts[0],
    });
    setCreateTablebigBlind({
      ...createtablebigBlind,
      'bigBlind': blindparts[1],
    });
  };
  
  


  const closeModal = () => {
    // setIsLoading(false);
    // setUserProfileEdit(false);
    // setChangePasswordForm(false);
    // setIsAccountModalOpen(false);
    // setIsGameHistoryModalOpen(false);
    // setIsSettingModalOpen(false);
    // setIsLogoutModalOpen(false);
    // setPage(1);
    props.closeCashier()
  }

  const buyPackage = (hour) => {
    const data = {
      playerId: userData.playerId,
      selectedHour: hour
    }
    socket.emit('buyHourlyPackage', data, (res) => {
      if (res.status == 'success') {
        toast.success(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const hideShowDiv = (id) => {
    if (id == 'deposit') {
      setDepositePaymentMethod('card');
      setIsCardMethodSelected(false)
      setDepositeCard('visa')
    }
  //  if (id == 'DepositHistory') {
  //    const data = {
  //      playerId: userData.playerId,
  //      productName: 'PokerHills'
  //    }
      
  //    socket.emit('purchaseHistory', data, (res) => {
  //      if (res.status == 'success') {
  //        console.log(res.result.playerChipsCashHistory);
  //        setIsActiveHistory(res.result.playerChipsCashHistory);
  //      } else {
  //        setIsActiveHistory({});
  //      }
  //    });
  //  }
    
    if (id == 'DepositHistory' || id == "allTra") {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    
    if (id == 'cashout') {
      setDepositePaymentMethod('bankTransfer');
    }
    if (id == 'redeemCode') {
      setCodeForm({
        code: ''
      });
    }
    
    if (id == 'tableCreate') {
      setCreateTableName({
        Name: ''
      });
      
    }
    
    if (id == 'lockedBonus') {
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('lockedBonus', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.bonusCodeHistory);
          setLockedBonusHistory(res.result.bonusCodeHistory);
        } else {
          setLockedBonusHistory({});
        }
      });
    }
    
    
    if (id == 'tableCreateList') {
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('createTableReqHistory', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.createTableReqHistory);
          setCreateTableReqHistory(res.result.createTableReqHistory);
          
          
        } else {
          setCreateTableReqHistory({});
        }
      });
      
      socket.emit('updatecreateTableReqView', data, (res) => {
          console.log(res);
        });
      
    }
    
    if (id == 'buyHour') {
      hourPackageListingReceived()
    }
    setIsActive({
      table: id,
    })
  }

  const hourPackageListingReceived = () => {
    const data = {
      playerId: userData.playerId,
      productName: 'PokerHills'
    }
    socket.emit('HourPackageList', data, (res) => {
      if (res.status == 'success') {
        setHourPackages(res.result)
      }
    });
  }

  const hideShowHandTab = (id,page) => {
    setPage(page)
    console.log(id);
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          console.log(res.result.WithdrawHistoryList);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    
    setIsActiveHistory({
      table: id,
    })
  }
  
  const changePage = (page) => {
    setPage(page)
    var id = isActiveHistory.table;
    console.log(id);
    if (id == 'CashHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('getWithdrawHistory', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          console.log(res.result.WithdrawHistoryList);
          setActiveHistoryList(res.result.WithdrawHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    if (id == 'DepositHistory') {
      const data = {
        playerId: userData.playerId,
        pageNo: page,
        productName: 'PokerHills'
      }
      socket.emit('getDepositHistory', data, (res) => {
        console.log(res)
        if (res.status == 'success') {
          console.log(res.result.DepositHistoryList);
          setActiveHistoryList(res.result.DepositHistoryList);
        } else {
          setActiveHistoryList({});
        }
      });
    }
    
    setIsActiveHistory({
      table: id,
    })
  }
  
  useEffect(() => {
    if (isActive.table === 'tableCreate') {
      
      setCreateTableName({
        ...createtableName,
        'Name': '',
      });
      setCreateTableMaxplayers({
        MaxPlayers: '2'
      });
      setCreateTablePrivacy({
        Privacy: 'public'
      });
      setCreateTablePassword({
        Password: ''
      });
      setCreateTableMinBuyIn({
        MinBuyIn: ''
      });
      setCreateTableMaxBuyIn({
        MaxBuyIn: ''
      });
      setCreateTableLimit({
        Limit: 'limit'
      });
      setCreateTablesmallBlind({
        smallBlind: '1000'
      });
      setCreateTablebigBlind({
        bigBlind: '2000'
      });
    } else if ( isActive.table == 'tableCreateList') {
      const data = {
        playerId: userData.playerId,
        productName: 'PokerHills'
      }
      socket.emit('createTableReqHistory', data, (res) => {
        if (res.status == 'success') {
          console.log(res.result.createTableReqHistory);
          setCreateTableReqHistory(res.result.createTableReqHistory);
          
          
        } else {
          setCreateTableReqHistory({});
        }
      });
      
      socket.emit('updatecreateTableReqView', data, (res) => {
          console.log(res);
        });
      
    }
  }, [isActive.table]);

  const mobile = window.innerWidth <= 1024;

  let customStyles = {
    content: {
      inset: '44% auto auto 49.5%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#474B6B',
      width: '60%',
      border: 'none',
      padding: '0px',
      borderRadius: '15px',
    },
  }

  if (mobile) {
    customStyles = {
      content: {
        inset: '50% auto auto 50%',
        background: 'transparent',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        border: 'none',
        padding: '0px',
      },
    }
  }

  return (
    <div >
      {/* <ToastContainer /> */}
      <Modal
        isOpen={isAccountModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ToastContainer />
        <div className={ isMobile ? "mobileView plyerProfile" : "plyerProfile" }>
            <div className="cashier-modal cashier">
              <div className='d-flex min-h-100'>
                <div className='p-col-3 modal-sidebar min-h-100'>
                  <div className={ isMobile ? 'modal-mobile-header' : 'modal-sidebar-header'}>
                    {/* <div className='login-logo'>
                      <img src={LoginImg} className="gameLogo" />
                    </div> */}
                    <div className='cashier-heading'>
                      {/*<h2>{lang.ko.cashier}</h2>*/}
                      <h2>테이블</h2>
                      { isMobile && (
                        <img className='close-modal-btn' src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                      )}
                    </div>
                  </div>
                  <div className={ isMobile ? 'cashier-modal-menu' : 'cashier-modal-body-sidebar'}>
                    {/*
                    <button className={isActive.table === 'deposit' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="deposit"
                      onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.deposit}</button>
                    <button className={isActive.table === 'cashout' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="cashout"
                      onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.cashout}</button>
                    { !isMobile && (
                      <hr></hr>
                    )} 
                    
                    <button className={isActive.table === 'allTra' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="allTra"
                      onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.transaction_history}</button>
                    { !isMobile && (
                      <hr></hr>
                    )}
                    */}
                    {hourSubscription == true ?
                      <button className={isActive.table === 'buyHour' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button'} id="buyHour"
                        onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.buy_hours}</button> : ''}
                        
                    <button className={isActive.table === 'tableCreate' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="tableCreate"
                      onClick={(e) => {hideShowDiv(e.target.id) }}>테이블 개설 요청</button>
                    <button className={isActive.table === 'tableCreateList' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="tableCreateList"
                      onClick={(e) => {hideShowDiv(e.target.id) }}>테이블 개설 리스트</button>
                    {/*<button className={isActive.table === 'redeemCode' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="redeemCode"
                      onClick={(e) => {hideShowDiv(e.target.id) }}>{lang.ko.reedem_code}</button>*/}
                    { !isMobile && (
                      <hr></hr>
                    )}
                    
                    {/*<button className={isActive.table === 'lockedBonus' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="lockedBonus"
                      onClick={(e) => { hideShowDiv(e.target.id) }}>{lang.ko.locked_bonus}</button>
                      
                    <hr></hr>
                    <button className={isActive.table === 'allTra' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="allTra"
                      onClick={(e) => {hideShowDiv(e.target.id)}}>{lang.ko.transaction_history}</button>*/}
                    
                    
                    {/*<button className={isActive.table === 'DepositHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="DepositHistory"
                      onClick={(e) => { hideShowDiv(e.target.id) }}>{lang.ko.deposit_history}</button>
                      
                    <button className={isActive.table === 'CashHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="CashHistory"
                      onClick={(e) => { hideShowDiv(e.target.id) }}>{lang.ko.cash_out_history}</button>
                      
                    <button className={isActive.table === 'BuyHourHistory' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button'} id="BuyHourHistory"
                      onClick={(e) => { hideShowDiv(e.target.id) }}>{lang.ko.buy_hours_history}</button>*/}
                  </div>
                  {/* <hr></hr>
                  <button className={isActive.table === 'buyHourHisoty' ? `btn modal-sidebar-button active` : 'btn modal-sidebar-button  '} id="buyHourHisoty"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.buy_hours_history}</button>
                  <button className={isActive.table === 'depositHisoty' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button  '} id="depositHisoty"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.deposit_history}</button>
                  <button className={isActive.table === 'cashoutHistory' ? `btn modal-sidebar-button  active` : 'btn modal-sidebar-button  '} id="cashoutHistory"
                    onClick={(e) => {
                      hideShowDiv(e.target.id)
                    }}>{lang.ko.cash_out_history}</button> */}
                </div>
                <div className='p-col-9 min-h-100 theme-bg'>
                  <div className='cashier-modal-card'>
                    <div className='cashier-modal-heading'>
                      {isActive.table === 'allTra' ?
                        <h2 >{lang.ko.all_transaction}</h2>
                        : isActive.table === 'buyHour' && hourSubscription ?
                          <h2 >{lang.ko.buy_hours}</h2>
                          : isActive.table === 'deposit' ?
                            <h2 >{lang.ko.deposit}</h2>
                            : isActive.table === 'cashout' ?
                              <h2 >{lang.ko.cashout}</h2>
                              : isActive.table === 'depositHisoty' ?
                                <h2 >{lang.ko.deposit_history}</h2>
                                : isActive.table === 'cashoutHistory' ?
                                  <h2 >Cashout History</h2>
                                  : isActive.table === 'redeemCode' ?
                                    <h2 >{lang.ko.reedem_code}</h2>
                                    : isActive.table === 'lockedBonus' ?
                                      <h2 >{lang.ko.locked_bonus}</h2>
                                      : isActive.table === 'tableCreate' ?
                                        <h2 >테이블 개설 요청</h2>
                                        : isActive.table === 'tableCreateList' ?
                                          <h2 >테이블 개설 리스트</h2>
                                          :  ''
                      }
                      { !isMobile && (
                        <img src={'../Images/close.png'} onClick={() => closeModal(false)}></img>
                      )}
                    </div>
                    <div className='cashier-modal-body'>
                      <div className={isActive.table === 'allTra' ? `allTra` : 'allTra d-none'}>
                        <div className='cahsierForm'>
                          <div className='d-flex gap-2 login_header'>
                            <h6 className={isActiveHistory.table === 'DepositHistory' ? 'active' : ''} id="DepositHistory" onClick={(e) => { hideShowHandTab(e.target.id,1) }}>{lang.ko.deposit_history}</h6>
                            <h6 className={isActiveHistory.table === 'CashHistory' ? 'active' : ''} id="CashHistory" onClick={(e) => { hideShowHandTab(e.target.id,1) }}>{lang.ko.cash_out_history}</h6>
                            {/*<h6 className={isActiveHistory.table === 'BuyHourHistory' ? 'active' : ''} id="BuyHourHistory" onClick={(e) => { hideShowHandTab(e.target.id) }}>{lang.ko.buy_hours_history}</h6>*/}
                          </div>
                          {isActiveHistory.table === 'CashHistory' ?
                            <div>
                              <table className='table p-lb-table table-borderless'>
                                <thead>
                                  <tr>
                                    <th>{lang.ko.sr_no}</th>
                                    <th>{lang.ko.date_time}</th>
                                    {/*<th>{lang.ko.refrerence_no}</th>*/}
                                    <th>{lang.ko.type}</th>
                                    <th>{lang.ko.status}</th>
                                    <th>{lang.ko.amount}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/*<tr>
                                    <td>1</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412198425</td>
                                    <td>Card Payment</td>
                                    <td>Success</td>
                                    <td>500</td>
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN15412135412</td>
                                    <td>Crypto Payment</td>
                                    <td>Success</td>
                                    <td>1000</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>10-05-2023 3:45 pm</td>
                                    <td>RN1541284512</td>
                                    <td>Cash Payment</td>
                                    <td>Success</td>
                                    <td>5000</td>
                                  </tr>*/}
                                   {activeHistoryList.length > 0 ? activeHistoryList.map((history, index) => {
                                   return (
                                       <tr>
                                        <td>{index}</td>
                                        <td>{moment(history.dateTime).format("YY/MM/DD HH:mm  ")}</td>
                                        <td>Cash Payment</td>
                                        <td>{history?.status}</td>
                                        <td>{history?.withdrawAmount}</td>
                                      </tr>
                                      );
                                   }) : <tr>
                                <td colSpan="7">No Game Found</td>
                              </tr>}
                                </tbody>
                              </table>
                              <div className="historyPagination">
                                <PaginationControl
                                  page={page}
                                  between={0}
                                  total={497}
                                  limit={20}
                                  changePage={(page) => {
                                    changePage(page);
                                  }}
                                  ellipsis={1}
                                />
                              </div>
                            </div>
                            : isActiveHistory.table === 'BuyHourHistory' ?
                              <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>{lang.ko.sr_no}</th>
                                      <th>{lang.ko.date_time}</th>
                                      <th>{lang.ko.refrerence_no}</th>
                                      <th>{lang.ko.type}</th>
                                      <th>{lang.ko.status}</th>
                                      <th>{lang.ko.hours}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412198425</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN15412135412</td>
                                      <td>Crypto Payment</td>
                                      <td>Success</td>
                                      <td>10</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>2</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>5</td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>10-05-2023 3:45 pm</td>
                                      <td>RN1541284512</td>
                                      <td>Cash Payment</td>
                                      <td>Success</td>
                                      <td>20</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              : <div>
                                <table className='table p-lb-table table-borderless'>
                                  <thead>
                                    <tr>
                                      <th>{lang.ko.sr_no}</th>
                                      <th>{lang.ko.date_time}</th>
                                      {/*<th>{lang.ko.refrerence_no}</th>*/}
                                      <th>{lang.ko.type}</th>
                                      <th>{lang.ko.status}</th>
                                      <th>{lang.ko.amount}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/*<tr>
                                      <td>1</td>
                                      <td>1-05-2023 1:00 pm</td>
                                      <td>RN15498745125</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>1000</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>18-05-2023 2:45 pm</td>
                                      <td>RN154129451251</td>
                                      <td>Card Payment</td>
                                      <td>Success</td>
                                      <td>500</td>
                                    </tr>*/}
                                    {activeHistoryList.length > 0 ? activeHistoryList.map((history, index) => {
                                   return (
                                       <tr>
                                        <td>{index}</td>
                                        <td>{moment(history.dateTime).format("YY/MM/DD HH:mm  ")}</td>
                                        <td>Deposit Payment</td>
                                        <td>{history?.status}</td>
                                        <td>{history?.depositAmount}</td>
                                      </tr>
                                      );
                                   }) : <tr>
                                <td colSpan="7">No Game Found</td>
                              </tr>}
                                  </tbody>
                                </table>
                                <div className="historyPagination">
                                <PaginationControl
                                  page={page}
                                  between={0}
                                  total={497}
                                  limit={20}
                                  changePage={(page) => {
                                    changePage(page);
                                  }}
                                  ellipsis={1}
                                />
                              </div>
                              </div>}
                        </div>
                      </div>
                      <div className={isActive.table === 'buyHour' ? `buyHour` : 'buyHour d-none'}>
                        <div className='buyHour-card-body'>
                          {hourPackages.length > 0 ? hourPackages.map((hourPackage, index) => {
                            return (
                              <div className='buyHourBox'>
                                <div className='package-name-header'>
                                  <p>{hourPackage.name}</p>
                                  <button className='btn buy-package-btn' onClick={() => buyPackage(hourPackage.hours)}>Buy Now</button>
                                </div>
                                <div className='package-box-body'>
                                  <ul>
                                    <li>Get {hourPackage.hours} Hour</li>
                                    <li>${hourPackage.rate} per hour</li>
                                  </ul>
                                </div>
                              </div>
                            );
                          }) : ''
                          }

                          {/* <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.booster}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('05:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 5 Hour</li>
                                <li>$22 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.bronze}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('10:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 10 Hour</li>
                                <li>$20 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.silver}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('20:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 20 Hour</li>
                                <li>$18 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.gold}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('30:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 30 Hour</li>
                                <li>$15 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.platinum}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('50:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 50 Hour</li>
                                <li>$12 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.titanium}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('75:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 75 Hour</li>
                                <li>$10 per hour</li>
                              </ul>
                            </div>
                          </div>
                          <div className='buyHourBox'>
                            <div className='package-name-header'>
                              <p>{lang.ko.diamond}</p>
                              <button className='btn buy-package-btn' onClick={() => buyPackage('100:00')}>Buy Now</button>
                            </div>
                            <div className='package-box-body'>
                              <ul>
                                <li>Get 100 Hour</li>
                                <li>$5 per hour</li>
                              </ul>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className={isActive.table === 'deposit' ? `deposit cashier-box` : 'deposit d-none'}>
                        {isCardMethodSelected ?
                          <form className='cahsierForm'>
                            <div className='form-group'>
                              <div>

                                <div className='d-flex gap-2'>
                                  <div className='card-payment-details'>

                                    <div className='form-group mt-0'>
                                      <label>{lang.ko.amount}</label>
                                      <button className='btn payment-method-btn active m-1 mt-0'>$ 100</button>
                                    </div>
                                    <div className='form-group mt-0 mt-1 mb-4'>
                                      <label>Card Service</label>
                                      {depositeCard === 'visa' ?
                                        <button className="btn payment-method-btn m-1 active">
                                          <div className='icon'><img src={'../Images/visa.png'} /></div>
                                          <p className='payment-method-name'>VISA</p>
                                        </button>
                                        : depositeCard === 'master' ?
                                          <button className="btn payment-method-btn m-1 active">
                                            <div className='icon'><img src={'../Images/mastercard.png'} /></div>
                                            <p className='payment-method-name'>MasterCard</p>
                                          </button>
                                          : depositeCard === 'maestro' ?
                                            <button className="btn payment-method-btn m-1 active">
                                              <div className='icon'><img src={'../Images/maestro.png'} /></div>
                                              <p className='payment-method-name'>Maestro</p>
                                            </button>
                                            : depositeCard === 'americanexpress' ?
                                              <button className="btn payment-method-btn m-1 active">
                                                <div className='icon'><img src={'../Images/americanexpress.png'} /></div>
                                                <p className='payment-method-name'>American Express</p>
                                              </button>
                                              : ''}
                                    </div>
                                    <div className='form-group mt-4'>
                                      <button className='btn payment-method-btn m-1 mt-4' onClick={(e) => setIsCardMethodSelected(false)}>Back</button>
                                    </div>
                                  </div>
                                  <div className='card-payment'>
                                    <div className='form-group mt-0'>
                                      <label>Card Holder Name</label>
                                      <input type="text" placeholder="Enter Card Holder Name"></input>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <label>Card Number</label>
                                      <input type="text" placeholder="XXXX-XXXX-XXXX-XXXX"></input>
                                    </div>
                                    <div className='d-flex gap-2'>
                                      <div className='form-group mt-3'>
                                        <label>Expired Date</label>
                                        <input type="text" placeholder="01/30"></input>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <label>CVV</label>
                                        <input type="password" placeholder="***"></input>
                                      </div>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <button type="button" className='deposit_withdraw_btn' >SUBMIT</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </form>
                          :
                          <form className='cahsierForm'>
                            <div className='form-group mt-0'>
                              <label>{lang.ko.amount}</label>
                              {depositAmount == 'custom' ?
                                <div className="inputWithIcon">
                                  <input type="text" placeholder="Amount..."></input>
                                </div>
                                : ""
                              }
                            </div>
                            <div className='form-group mt-0'>
                              <div className='d-flex'>
                                <button className={depositAmount == '100' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('100')}>$ 100</button>
                                <button className={depositAmount == '500' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('500')}>$ 500</button>
                                <button className={depositAmount == '1000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('1000')}>$ 1,000</button>
                                <button className={depositAmount == '2000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('2000')}>$ 2,000</button>
                                <button className={depositAmount == 'custom' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('custom')}>{lang.ko.custom}</button>
                              </div>
                            </div>
                            <div className='d-flex gap-2 login_header mt-3'>
                              <h6 className={depositePaymentMethod == 'card' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('card')}>{lang.ko.card}</h6>
                              <h6 className={depositePaymentMethod == 'crypto' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('crypto')}>{lang.ko.cryptocurrency}</h6>
                              <h6 className={depositePaymentMethod == 'other' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('other')}>Other</h6>
                              <h6 className={depositePaymentMethod == 'manual' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('manual')}>{lang.ko.manual}</h6>
                            </div>
                            <div className='form-group'>
                              {depositePaymentMethod === 'manual' ?
                                <div>
                                  <div className='form-group mt-0'>
                                    <label>{lang.ko.deposit_receipt}</label>
                                    <div className="inputWithIcon mt-1">
                                      <input type="file"></input>
                                    </div>
                                  </div>
                                  <div className='form-group mt-3'>
                                    <button type="button" className='deposit_withdraw_btn' >{lang.ko.deposit}</button>
                                  </div>
                                </div>
                                : depositePaymentMethod === 'card' ?
                                  <div>
                                    {isMobile ?
                                      <div >
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                          </button>
                                          <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                          </button>
                                        </div>
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                          </button>
                                          <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                          </button>
                                        </div>
                                      </div>
                                      :
                                      <div className='d-flex'>
                                        <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                        </button>
                                        <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                        </button>
                                        <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                        </button>
                                        <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                        </button>
                                      </div>
                                    }
                                    <div className='form-group mt-3'>
                                      <label>{lang.ko.bonus_deposite_code}</label>
                                      <input type="text" placeholder="Bonus Deposite Code"></input>
                                    </div>
                                    <div className='form-group mt-3'>
                                      <button type="button" className='deposit_withdraw_btn' onClick={(e) => setIsCardMethodSelected(true)}>{lang.ko.deposit}</button>
                                    </div>
                                  </div>
                                  : depositePaymentMethod === 'crypto' ?
                                    <div>
                                      <div className='d-flex'>
                                        <button className={depositeCard === 'bitcoin' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)}>
                                          <div className='icon'><img src={'../Images/bitcoin.png'} id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                          <p className='payment-method-name' id="bitcoin" onClick={(e) => setDepositeCard(e.target.id)}>Bitcoin</p>
                                        </button>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <label>{lang.ko.bonus_deposite_code}</label>
                                        <input type="text" placeholder="Bonus Deposite Code"></input>
                                      </div>
                                      <div className='form-group mt-3'>
                                        <button type="button" className='deposit_withdraw_btn' >{lang.ko.deposit}</button>
                                      </div>
                                    </div>
                                    : depositePaymentMethod === 'other' ?
                                      <div>
                                        <div className='d-flex'>
                                          <button className={depositeCard === 'paypal' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="paypal" onClick={(e) => setDepositeCard(e.target.id)}>
                                            <div className='icon'><img src={'../Images/paypal.png'} id="paypal" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                            <p className='payment-method-name' id="paypal" onClick={(e) => setDepositeCard(e.target.id)}>{lang.ko.paypal}</p>
                                          </button>
                                        </div>
                                        <div className='form-group mt-3'>
                                          <label>{lang.ko.bonus_deposite_code}</label>
                                          <input type="text" placeholder="Bonus Deposite Code"></input>
                                        </div>
                                        <div className='form-group mt-3'>
                                          <button type="button" className='deposit_withdraw_btn' >{lang.ko.deposit}</button>
                                        </div>
                                      </div>
                                      : ''}
                            </div>

                          </form>
                        }
                      </div>
                      <div className={isActive.table === 'cashout' ? `cashout cashier-box` : 'cashout d-none'}>
                        <form className='cahsierForm'>
                          <div className='form-group mt-0'>
                            <label>{lang.ko.amount}</label>
                            {depositAmount == 'custom' ?
                              <div className="inputWithIcon">
                                <input type="text" placeholder="Amount..."></input>
                              </div>
                              : ""
                            }
                          </div>
                          <div className='form-group mt-0'>
                            <div className='d-flex'>
                              <button className={depositAmount == '100' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('100')}>$ 100</button>
                              <button className={depositAmount == '500' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('500')}>$ 500</button>
                              <button className={depositAmount == '1000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('1000')}>$ 1,000</button>
                              <button className={depositAmount == '2000' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('2000')}>$ 2,000</button>
                              <button className={depositAmount == 'custom' ? 'btn payment-method-btn active m-1 mt-0' : 'btn payment-method-btn m-1 mt-0'} onClick={(e) => setDepositAmount('custom')}>{lang.ko.custom}</button>
                            </div>
                          </div>
                          <div className='d-flex gap-2 login_header mt-3'>
                            <h6 className={depositePaymentMethod == 'bankTransfer' ? 'active' : ''} onClick={(e) => setDepositePaymentMethod('bankTransfer')}>{lang.ko.bank_transform}</h6>
                          </div>
                          <div className='form-group'>
                            {depositePaymentMethod === 'card' ?
                              <div>
                                <div className='d-flex'>
                                  <button className={depositeCard === 'visa' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="visa" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/visa.png'} id="visa" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="visa" onClick={(e) => setDepositeCard(e.target.id)}>VISA</p>
                                  </button>
                                  <button className={depositeCard === 'master' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="master" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/mastercard.png'} id="master" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="master" onClick={(e) => setDepositeCard(e.target.id)}>MasterCard</p>
                                  </button>
                                  <button className={depositeCard === 'maestro' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/maestro.png'} id="maestro" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="maestro" onClick={(e) => setDepositeCard(e.target.id)}>Maestro</p>
                                  </button>
                                  <button className={depositeCard === 'americanexpress' ? `btn payment-method-btn m-1 active` : 'btn payment-method-btn m-1'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>
                                    <div className='icon'><img src={'../Images/americanexpress.png'} id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)} /></div>
                                    <p className='payment-method-name' id="americanexpress" onClick={(e) => setDepositeCard(e.target.id)}>American Express</p>
                                  </button>
                                </div>
                                <div className='form-group mt-3'>
                                  <button type="button" className='deposit_withdraw_btn' >{lang.ko.withdraw}</button>
                                </div>
                              </div>
                              : depositePaymentMethod === 'bankTransfer' ?
                                <div>
                                  <div className='form-group mt-0'>
                                    <label>{lang.ko.bank_name}</label>
                                    <input type="text" placeholder=" Enter Bank Name"></input>
                                  </div>
                                  <div className='form-group mt-0'>
                                    <label>{lang.ko.bank_account_number}</label>
                                    <input type="text" placeholder="Enter Bank Account Number"></input>
                                  </div>
                                  <div className='form-group mt-0'>
                                    <label>{lang.ko.bank_code}</label>
                                    <input type="text" placeholder="Enter Bank Code"></input>
                                  </div>
                                  <div className='form-group mt-3'>
                                    <button type="button" className='deposit_withdraw_btn' >{lang.ko.withdraw}</button>
                                  </div>
                                </div>
                                : ''}
                          </div>

                        </form>
                      </div>
                      <div className={isActive.table === 'redeemCode' ? `redeemCode cashier-box` : 'redeemCode d-none'}>
                        <form className='cahsierForm'>
                          <div className='form-group mt-0'>
                            <label>{lang.ko.promotional_or_bonus_code}</label>
                            <div className="inputWithIcon">
                              <input type="text"
                                id="code"
                                value={codeForm.code}
                                onChange={handleChange}
                                placeholder="Enter Bonus Code" />
                              {/* <span className='invalid-text'>Codee has beed expired</span> */}
                            </div>
                          </div>
                          <div className='form-group'>
                            <div>
                              <div className='form-group mt-3'>
                                <button type="button" className='deposit_withdraw_btn' onClick={() => reedemCode()}>{lang.ko.reedem_code}</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      
                      <div className={isActive.table === 'tableCreate' ? `tableCreate cashier-box` : 'tableCreate d-none'}>
                        <form className='cahsierForm'>
                          <div className='form-group mt-0'>
                            <label>이름</label>
                            <div className="inputWithIcon">
                              <input type="text"
                                id="Name"
                                value={createtableName.Name}
                                onChange={handleChangeTableName}
                                placeholder="테이블 이름" />
                            </div>
                            <label>최대플레이어</label>
                            <div className="inputWithIcon">
                              <select class="form-control valid" name="maxPlayers" id="MaxPlayers"  aria-invalid="false" value={createtableMaxplayers.MaxPlayers} onChange={handleChangeTableMaxPlayers} >
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <label>테이블공개</label>
                            <div className="inputWithIcon">
                                <select class="form-control valid" name="tablePrivacy" id="Privacy" aria-invalid="false" value={createtablePrivacy.Privacy} onChange={handleChangeTablePrivacy}>
                                    <option value="public">공개
                                    </option>
                                    <option value="private">비공개
                                    </option>
                                </select>
                            </div>
                            <div className={isActivePassword == true ? `tableCreatePassword` : 'tableCreatePassword d-none'}>
                            <label>비밀번호</label>
                            <div className="inputWithIcon">
                                <input type="text"
                                  id="Password"
                                  value={createtablePassword.Password}
                                  onChange={handleChangeTablePassword}
                                  placeholder="비밀번호" />
                            </div>
                            </div>
                            <label>블라인드</label>
                            <div className="inputWithIcon">
                                <select class="form-control valid" id="stacks" name="stacks" aria-invalid="false" onChange={handleChangeBlind}>        
                                    <option value="1000/2000">1000/2000</option>
                                    <option value="2000/4000">2000/4000</option>
                                    <option value="3000/6000">3000/6000</option>
                                    <option value="500/1000">500/1000</option>
                                    <option value="4000/8000">4000/8000</option>
                                    <option value="5000/10000">5000/10000</option>
                                    <option value="10000/20000">10000/20000</option>
                                    <option value="20000/40000">20000/40000</option>
                                </select>
                            </div>
                            {/*<label>리밋</label>
                            <div className="inputWithIcon" >
                                <select class="form-control valid" name="limit" id="limit" aria-invalid="false"  value={createtableLimit.limit}  onChange={handleChangeLimit} >
                                    <option value="limit">
                                        리밋
                                    </option>
                                    <option value="no_limit">
                                      무제한
                                    </option>
                                    <option value="pot_limit">
                                      팟리밋
                                    </option>
                                </select>
                            </div>*/}
                            <label>최소바이인</label>
                            <div className="inputWithIcon">
                              <input type="text"
                                id="MinBuyIn"
                                value={createtableMinBuyIn.MinBuyIn}
                                onChange={handleChangeMinBuyIn}
                                placeholder="최소바이인" />
                            </div>
                            <label>최대바이인</label>
                            <div className="inputWithIcon">
                              <input type="text"
                                id="MaxBuyIn"
                                value={createtableMaxBuyIn.MaxBuyIn}
                                onChange={handleChangeMaxBuyIn}
                                placeholder="최대바이인" />
                            </div>
                          </div>
                          <div className='form-group'>
                            <div>
                              <div className='form-group'>
                                <button type="button" className='deposit_withdraw_btn' onClick={() => createTableReq()}>요청</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      
                      
                      <div className={isActive.table === 'tableCreateList' ? `tableCreateList cashier-box` : 'tableCreateList d-none'}>
                        <div className='cahsierForm'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                {/* <th>{lang.ko.sr_no}</th> */}
                                <th>이름</th>
                                <th>최대인원</th>
                                <th>공개</th>
                                <th>최소바이인</th>
                                <th>최대바이인</th>
                                <th>블라인드</th>
                                {/*<th>리밋</th>*/}
                                <th>개설시간</th>
                                <th>상태</th>
                              </tr>
                            </thead>
                            <tbody>
                              {createTableReqHistory.length > 0 ? createTableReqHistory.map((history, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <th>{index + 1}</th> */}
                                    <th>{history.name}</th>
                                    <th>{history.maxPlayers}</th>
                                    <th>
                                      {history.privacy === "public" ? "공개" 
                                      : history.privacy === "private" ? "비공개" 
                                      : history.privacy}
                                    </th>
                                    <th>{history.minbuyin}</th>
                                    <th>{history.maxbuyin}</th>
                                    <th>{history.smallBlind} / {history.bigBlind}</th>
                                    {/*<th>
                                      {history.limit === "limit" ? "리밋" 
                                      : history.limit === "no_limit" ? "무제한" 
                                      : history.limit === "pot_limit" ? "팟리밋" 
                                      : history.limit}
                                    </th>*/}
                                    <th>{history.updatedAt ? moment(history.updatedAt).format("DD MMM HH:mm") : ""}</th>
                                    <th>{history.status}</th>
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="9">No Data Found</td>
                              </tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      
                      <div className={isActive.table === 'lockedBonus' ? `lockedBonus cashier-box` : 'lockedBonus d-none'}>
                        <div className='cahsierForm'>
                          <table className='table p-lb-table table-borderless'>
                            <thead>
                              <tr>
                                {/* <th>{lang.ko.sr_no}</th> */}
                                <th>{lang.ko.code}</th>
                                <th>{lang.ko.bonus_amount}</th>
                                <th>{lang.ko.created_date}</th>
                                <th>{lang.ko.available_date}</th>
                                <th>{lang.ko.expiry_date}</th>
                                <th>{lang.ko.action}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {lockedBonusHistory.length > 0 ? lockedBonusHistory.map((history, index) => {
                                return (
                                  <tr key={index}>
                                    {/* <th>{index + 1}</th> */}
                                    <th>{history.name}</th>
                                    <th>{history.amount}</th>
                                    <th>{moment(history.createdAt).format("DD MMM, YYYY")}</th>
                                    <th>{moment(history.availableDate).format("DD MMM, YYYY")}</th>
                                    <th>{moment(history.valid_till).format("DD MMM, YYYY")}</th>
                                    <th>{history.status == 'Available' ?
                                      <button type="button" className='claim_btn' onClick={() => claimCode(history._id)}>{lang.ko.claim}</button> : history.status}</th>
                                  </tr>
                                );
                              }) : <tr>
                                <td colSpan="7">No Data Found</td>
                              </tr>}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        {/* {isLoading && userProfileEdit ?
          <div>
            <div>
              {userProfilePicOption.map((pic, index) => {
                return userProfilePhoto == index ?
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic activeProfile'></img> :
                  <img onClick={() => setUserProfilePhoto(index)} key={index} src={playerProfilePhoto(index)} className='plyrProfilePic'></img>
              })}
            </div>
            <div className='avtarBtnSection'>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(0)}>{lang.ko.update}</button>
              <button className='edtAvtarBtn' onClick={() => changePlayerAvtar(1)}>{lang.ko.cancle}</button>
            </div>
          </div>
          : isLoading && changePasswordForm ?
            <div className='plyerProfile'>
              <center>
                <form>
                  <div className='form-group'>
                    <label>{lang.ko.current_password}</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>{lang.ko.new_password}</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='form-group'>
                    <label>{lang.ko.confirm_password}</label>
                    <input className='form-control'></input>
                  </div>
                  <div className='avtarBtnSection'>
                    <button className='edtAvtarBtn' onClick={() => changePassword(0)}>{lang.ko.change}</button>
                    <button className='edtAvtarBtn' onClick={() => changePassword(1)}>{lang.ko.cancle}</button>
                  </div>
                </form>
              </center>
            </div>
            : isLoading ?
              
              : <div className="modalLoading" ><img src={'../Images/Load.gif'}></img></div>
        } */}
      </Modal >
    </div >
  );
};

export default Cashier

