import React from 'react'
import './index.css'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import socket from '../../socket';
import socketConfig from '../../Socket/config.js'
import { useCookies } from 'react-cookie';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const lang = require('../../Config/lang.js');

const INITIAL_SIGNUP_STATE = {
  password: '',
};

const CashGameTable = (props) => {
  const [signupForm, setSignupForm] = useState(INITIAL_SIGNUP_STATE);
  const [cookies, setCookie] = useCookies(['roomId', 'roomType', 'gameType', 'gameFormat']);
  const [gameTableData, setGameTable] = useState([])
  const [interval, setIntervalId] = useState('')
  const [stackList, setStackList] = useState([])
  const [dataLimit, setDataLimit] = useState('all')
  const [tableTypes, setTableTypes] = useState('all')
  const [playerLimit, setPlayerLimit] = useState('all')
  const [stack, setStack] = useState('all')
  const [isMobile, setIsMobile] = useState(props.mobileView)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPasswordPopupOpen, setPasswordIsPopupOpen] = useState(false)
  const [joinRoomId, setJoinRoomId] = useState('')
  const [selectedRow, setSelectedRow] = useState(null);

  const navigate = useNavigate();
  const playerData = JSON.parse(localStorage.getItem('user'));
  
  

  useEffect(() => {
    let currentDate = new Date();
    let fiveDaysAgo = new Date(currentDate.getTime() - (5 * 24 * 60 * 60 * 1000));
    
    socket.emit('GetStacks', [], (res) => {
      setStackList(res.result);
    });

    let data = {
      pokerGameType: tableTypes,
      game: dataLimit,
      stacks: stack,
      maxPlayer: playerLimit,
      playerId: playerData?.playerId,
      start: fiveDaysAgo,
      appVersion: '2.0',
      device_id: '4w85u349865neiuyjn38572h385uhwnuit4gn',
    }

    socket.emit('SearchLobby', data, (res) => {
      if (res.result) {
        setGameTable(res.result);
      }
    });

    const intervalId = setInterval(() => {
      socket.emit('SearchLobby', data, (res) => {
        if (res.result) {
          setGameTable(res.result);
        }
      });
    }, 5000);

    setIntervalId(intervalId);

    return () => clearInterval(intervalId)

  }, [dataLimit, tableTypes, stack, playerLimit])

  const refreshData = (() => {
    props.refreshData();
  });
  const filterDataTable = (value, type) => {
    clearInterval(interval);
    if (type == 'limit') {
      setDataLimit(value);
    }
    if (type == 'table') {
      setTableTypes(value);
    }
    if (type == 'player') {
      setPlayerLimit(value);
    }
    if (type == 'stack') {
      setStack(value);
    }
  }
  
  const translationMap = {
    "Password was wrong": "비밀번호가 틀렸습니다",
    // Add more mappings as needed
  };

  const joinTableWithPassword = () => {
    let data = {
      roomId: joinRoomId,
      password: signupForm.password
    }
    console.log(data);
    socket.emit('PrivateRoomLogin', data, (res) => {
      console.log(res);
      if (res.status == 'success') {
        navigate('/game/' + res.result[0]._id)
      } else {
        
      const translatedMessage = translationMap[res.message] || res.message;
      // toast.error(res.message, {
      
      toast.error(translatedMessage, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  }

  const joinTable = (data) => {

    const PlayerProfileData = {
      playerId: playerData.playerId,
      productName: 'PokerHills'
    }
    
    
    socket.emit('Playerprofile', PlayerProfileData, (res) => {
      const playerData = res.result;
      if (playerData.is_able) {
        if (data.isPasswordProtected == true) {
          //--- 참가되었을때 바로 입장
          const matchedRoom = gameTableData.find(
            (gameData) => gameData.roomId === data.roomId && gameData.isJoin === true
          );
          if(matchedRoom) {
            navigate('/game/' + data.roomId);
          } else {
            setJoinRoomId(data.roomId);
            setPasswordIsPopupOpen(true);
          }
        } else {
          // setCookie('roomId', data.roomId, { path: '/' });
          // setCookie('roomType', data.namespaceString, { path: '/' });
          // setCookie('gameType', data.pokerGameType, { path: '/' });
          // setCookie('gameFormat', 'cash_game', { path: '/' });
          navigate('/game/' + data.roomId)
        }
        // if (isMobile) {
        //   navigate('/game')
        // } else {
        //   const aspectRatio = 16 / 9; // 4:3 aspect ratio
        //   const width = 1080; // initial width
        //   const height = Math.round(width / aspectRatio); // calculate height based on aspect ratio
        //   const windowFeatures = `resizable=no,width=${width},height=${height}`;
        //   const newWindow = window.open(process.env.REACT_APP_URL + "#/game", "_blank", windowFeatures);
        //   const resizeWindow = () => {
        //     const currentWidth = newWindow.outerWidth;
        //     const currentHeight = newWindow.outerHeight;
        //     const expectedHeight = Math.round(currentWidth / aspectRatio);
        //     if (currentHeight !== expectedHeight) {
        //       newWindow.resizeTo(currentWidth, expectedHeight);
        //     }
        //   };
        //   newWindow.addEventListener("resize", resizeWindow);
        // }
      } else {
        setIsPopupOpen(true)
      }
    });

    // props.isLoader(true);
    // navigate('/game')
    // localStorage.removeItem("tableData");
    // localStorage.removeItem("roomId");
    // localStorage.removeItem("roomType");
    // localStorage.removeItem("gameFormat");
    // localStorage.setItem('roomId', JSON.stringify(data.roomId));
    // localStorage.setItem('roomType', JSON.stringify(data.namespaceString));
    // localStorage.setItem('gameType', JSON.stringify(data.pokerGameType));
    // localStorage.setItem('gameFormat', JSON.stringify('cash_game'));
    // props.isLoader(false);
  }

  const tableInfo = (room,index) => {
    props.setRoomDetails({
      pokerGameType: tableTypes,
      roomId: room.roomId,
      type: room.namespaceString,
    });
    
    setSelectedRow(index);
  }

  const closeModal = () => {
    setIsPopupOpen(false);
    setPasswordIsPopupOpen(false);
  }

  const buyHourModal = () => {
    setIsPopupOpen(false);
    setPasswordIsPopupOpen(false);
    props.openCashier();
  }

  const handleSignupChange = (event) => {
    setSignupForm({
      ...signupForm,
      [event.target.id]: event.target.value,
    });
  };

  let customStylesModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000000cc',
      width: '40%',
      height: '50%',
      border: '1px solid #ffd050'
    },
  };

  let customStylesPasswordModal = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#000000cc',
      width: '35%',
      height: '30%',
      border: '1px solid #ffd050'
    },
  };

  if (isMobile) {
    customStylesModal = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000cc',
        width: '60%',
        height: '30%',
        border: '1px solid #ffd050'
      },
    };

    customStylesPasswordModal = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000000cc',
        width: '75%',
        height: '25%',
        border: '1px solid #ffd050'
      },
    };
  }

  return (
    <div>
      {/* <ToastContainer /> */}
      {isMobile ?
        <div className="mt-2">
          <div className='tableFilterHead d-flex gap-2'>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>{lang.ko.limit}</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="limit">{lang.ko.limit}</option>
                  <option value="no_limit">{lang.ko.no_limit}</option>
                  <option value="pot_limit">{lang.ko.pot_limit}</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>{lang.ko.table}</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="texas">{lang.ko.texas}</option>
                  <option value="omaha">{lang.ko.omaha}</option>
                  {/* <option value="PLO5">PLO5</option> */}
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box'>
                <p>{lang.ko.player}</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='mb-p-hd-fill-box border-none'>
                <p>{lang.ko.stakes}</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                  <option value="all">{lang.ko.all}</option>
                  {stackList.length > 0 ? stackList.map((stack, index) => {
                    return (
                      <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                    );
                  }) : ''
                  };
                </select>
              </div>
            </div>
          </div>
          <div className='mobileTableList tableFixHead mt-2'>
            <div className='p-lb-table table-borderless'>
              {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                return (
                  <div className='tableListBox'>
                    <div className='d-flex gap-2'>
                      <div className='p-col-5'>
                        <p className='gameTableName m-0'>{cashGame.roomName}</p>
                        <p className='gameStakes m-0'>{cashGame.smallBlind}/{cashGame.bigBlind}</p>
                      </div>
                      <div className='p-col-2'>
                        <p className='gameBuyIn m-0'>{cashGame.minBuyIn} <br /><span style={{ color: "#ffd050" }}>{cashGame.maxBuyIn}</span></p>
                      </div>
                      <div className='p-col-3'>
                        <p className='gamePlayer m-0'><img src={'../Images/users.png'} width={'30%'}></img> {cashGame.playerCount}/{cashGame.maxPlayers}</p>
                        <p className='gameType m-0' style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</p>
                      </div>
                      <div className='p-col-2 px-1'>
                        <div className='wrapper-div'>
                          {
                            cashGame.isJoin==true?
                              <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.joining}</button>
                              :<button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                          }
                          {/* {playerData.is_able == true ?
                            <div>
                              <button className='btn p-tb-join-btn' onClick={() => setIsPopupOpen(true)}>{lang.ko.join}</button>

                              {cashGame.pokerGameType == 'texas' ?
                                <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                                :
                                <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                              }
                            </div>
                            :
                            <button className='btn p-tb-join-btn' onClick={() => setIsPopupOpen(true)}>{lang.ko.join}</button>
                          } */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }) :
                <div className='tableListBox  mt-2'>
                  <div className='text-center p-2'>
                    <p className='gameStakes m-1'>No Game Found</p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="sectionBox mt-2">
          <div className='tableFilterHead d-flex gap-2'>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>{lang.ko.limit} :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'limit')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="limit">{lang.ko.limit}</option>
                  <option value="no_limit">{lang.ko.no_limit}</option>
                  <option value="pot_limit">{lang.ko.pot_limit}</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>{lang.ko.table} :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'table')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="texas">{lang.ko.texas}</option>
                  <option value="omaha">{lang.ko.omaha}</option>
                  {/* <option value="PLO5">PLO5</option> */}
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box'>
                <p>{lang.ko.player} :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'player')}>
                  <option value="all">{lang.ko.all}</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </div>
            <div className='p-col-3'>
              <div className='p-hd-fill-box border-none'>
                <p>{lang.ko.stakes} :</p>
                <select onChange={(e) => filterDataTable(e.target.value, 'stack')}>
                  <option value="all">{lang.ko.all}</option>
                  {stackList.length > 0 ? stackList.map((stack, index) => {
                    return (
                      <option key={index} value={stack.minStack + '/' + stack.maxStack}>{stack.minStack}/{stack.maxStack}</option>
                    );
                  }) : ''
                  };
                </select>
              </div>
            </div>
          </div>
          <div className="tableFixHead">
            <table className='table p-lb-table table-borderless'>
              <thead>
                <tr>
                  <th>{lang.ko.privacy}</th>
                  {/*<th>{lang.ko.type}</th>*/}
                  <th>{lang.ko.table_name}</th>
                  <th>{lang.ko.game}</th>
                  {/* <th>{lang.ko.stakes}</th> */}
                  <th>{lang.ko.min_buyin}</th>
                  <th>{lang.ko.max_buyin}</th>
                  <th>블라인드</th>
                  <th>{lang.ko.players}</th>
                  <th>{lang.ko.action}</th>
                </tr>
              </thead>
              <tbody>
                {gameTableData.length > 0 ? gameTableData.map((cashGame, index) => {
                  return (
                    <tr key={index} onClick={(e) => { tableInfo(cashGame,index) }} className={selectedRow === index ? 'selected' : ''}>
                      {/*<td>{cashGame.type == "LTH" ? "리밋" : cashGame.type == "PLTH" ? "팟제한" : cashGame.type == "NLTH" ? "무제한" : ""}</td>*/}
                      <td>{cashGame.isPasswordProtected == true ? '비공개' : '공개'}</td>
                      <td>{cashGame.roomName}
                        {/* - ({cashGame.smallBlind}/{cashGame.bigBlind}) */}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>{cashGame.pokerGameType}</td>
                      {/* <td></td> */}
                      <td>{cashGame.minBuyIn}</td>
                      <td>{cashGame.maxBuyIn}</td>
                      <td>
                        {cashGame.smallBlind}/{cashGame.bigBlind}
                      </td>
                      <td>{cashGame.playerCount}/{cashGame.maxPlayers}</td>
                      <td>{cashGame.isPasswordProtected == true ? '비공개' : '공개'}</td>
                      <td className='join-td'>
                        {
                          cashGame.isJoin==true?
                            <button className='btn p-tb-join-btn joining-btn' onClick={() => joinTable(cashGame)}>{lang.ko.joining}</button>
                            :<button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                        }
                        {/*<button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>*/}

                        {/* {playerData.is_able == true ?
                          <div>
                            {cashGame.pokerGameType == 'texas' ?
                              <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                              :
                              <button className='btn p-tb-join-btn' onClick={() => joinTable(cashGame)}>{lang.ko.join}</button>
                            }
                          </div>
                          :
                          <button className='btn p-tb-join-btn' onClick={() => setIsPopupOpen(true)}>{lang.ko.join}</button>
                        } */}
                      </td>
                    </tr>
                  );
                }) : <tr>
                  <td colSpan="8">No Game Found</td>
                </tr>}
              </tbody>
            </table>
          </div>
        </div>
      }
      <Modal
        isOpen={isPopupOpen}
        onRequestClose={closeModal}
        style={customStylesModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody'>
            <center>
              <h2 className='color-1'>{lang.ko.warning}</h2>
              <h4 className='color-1 mt-4'>Sorry, you have insufficient hours balance to play game. Please buy hour to continue.</h4>
              <div className='avtarBtnSection'>
                <button className='selectBuyInBtn' onClick={() => buyHourModal()}>Buy Hour</button>
                <button className='selectBuyInBtn' onClick={() => closeModal()}>Close</button>
              </div>
            </center>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isPasswordPopupOpen}
        onRequestClose={closeModal}
        style={customStylesPasswordModal}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <ToastContainer />
        <div className={isMobile ? "gameModel mobileView" : 'gameModel'}>
          <div className='modalBody'>
            <form className='cashier-box' onSubmit={(e) => {
                e.preventDefault();
              }}>
              <div className='form-group mt-0'>
                <label>{lang.ko.enter_room_password}</label>
                <input type="password" id="password" value={signupForm.username}
                  onChange={handleSignupChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      joinTableWithPassword()
                    }
                  }}
                  placeholder="패스워드를 입력해주세요"></input>
              </div>
            </form>
            <div className='avtarBtnSection'>
              <button className='selectBuyInBtn' onClick={() => joinTableWithPassword()}>{lang.ko.join}</button>
              <button className='selectBuyInBtn' onClick={() => closeModal()}>{lang.ko.close}</button>
            </div>
          </div>
        </div>
      </Modal>
    </div>

  );
}

export default CashGameTable

