module.exports = {
    'ko':{
		"login": "로그인",
		"create_account": "계정 생성",
		"connect": "연결",
		"enter_email_or_username": "이메일 또는 아이디 입력",
		"password": "비밀번호",
		"create_an_account": "계정 생성",
		"email_or_username": "이메일 또는 아이디",
		"enter_email_or_username": "이메일 또는 아이디 입력",
		"phone_number": "전화 번호",
		"enter_phone_number": "전화 번호 입력",
		"confirm_password": "비밀번호 확인",
		"enter_confirm_password": "비밀번호 확인 입력",
		"connect_with": "연결하기",
		"metamask": "메타마스크",
		"trustwallet": "트러스트월렛",
		"binance_chain_wallet": "바이낸스 체인 월렛",
		"walletconnect": "월렛커넥트",
		"mathwallet": "매쓰월렛",
		"tokenpocket": "토큰포켓",
		"blinds": "블라인드",
		"available_chips": "사용할 수 있는 칩",
		"players": "플레이어",
		"tables": "테이블",
		"name": "이름",
		"seat": "시트",
		"tournaments": "토너먼트",
		"cashier": "캐시어",
		"cashgame": "현금 게임",
		"tournament": "토너먼트",
		"sit_go": "즉석 토너먼트",
		"limit": "리밋",
		"all": "전체",
		"no_limit": "무제한",
		"pot_limit": "팟제한",
		"table": "테이블",
		"texas": "텍사스",
		"omaha": "오마하",
		"player": "플레이어",
		"stakes": "블라인드",
		"no_table_selected": "선택된 테이블 없음",
		"chips": "칩",
		"type": "타입",
		"select": "선택",
		"table_name": "테이블 이름",
		"game": "게임",
		"buyin": "바이인",
		"min": "최소",
		"max": "최대",
		"min_buyin": "최소 바이인",
		"max_buyin": "최대 바이인",
		"players": "플레이어",
		"privacy": "공개여부",
		"action": "액션",
		"my_account": "내 계정",
		"profile": "프로필",
		"first_name": "이름",
		"last_name": "성",
		"username": "아이디",
		"email": "이메일",
		"mobile": "휴대폰",
		"total_chips": "전체 칩",
		"total_balance_hours": "전체 잔여 시간",
		"vip_level": "VIP 레벨",
		"edit": "편집",
		"change_avatar": "아바타 변경",
		"update": "갱신",
		"change_password": "비밀번호 변경",
		"current_password": "현재 비밀번호",
		"enter_current_password": "현재 비밀번호 입력",
		"new_password": "새 비밀번호",
		"confirm_password": "비밀번호 확인",
		"confirm_password": "비밀번호 확인",
		"change": "변경",
		"my_affiliates": "나의 제휴",
		"alertmsg_givebonus": "제휴코드를 생성하고 친구에게 알려줘서 보너스를 받으세요",
		"tap_to_create": "생성하기",
		"total_signup": "전체 가입",
		"total_bonus": "전체 보너스",
		"total_commission": "전체 커미션",
		"account_security": "계정 보안",
		"account_settings": "계정 설정",
		"factory_authentication": "이중 인증",
		"security_questions": "보안 질문",
		"settings": "설정",
		"sound_settings": "소리 설정",
		"vibration_settings": "진동 설정",
		"push_settings": "푸시 알림 설정",
		"hands_history": "핸드 히스토리",
		"date_time": "날짜와 시간",
		"game_name": "게임 이름",
		"winning_amount": "승리 금액",
		"hands": "핸드",
		"best_cards": "베스트 카드",
		"logout": "로그아웃",
		"warning": "경고",
		"yes": "예",
		"no": "아니요",
		"alert_msg_leave_game": "정말 게임에서 나가시나요?",
		"alert_msg_leave_game_next_hand": "핸드 끝나면 나가기 예약!",
		"cashier": "캐시어",
		"deposit": "입금",
		"amount": "금액",
		"custom": "직접입력",
		"card": "카드",
		"cryptocurrency": "암호화폐",
		"bitcoin": "비트코인",
		"other": "기타",
		"paypal": "페이팔",
		"manual": "수동",
		"deposit_receipt": "입금증",
		"bonus_deposite_code": "입금 보너스 코드",
		"deposit": "입금",
		"cashout": "출금",
		"amount": "금액",
		"bank_transform": "은행 이체폼",
		"bank_name": "은행 이름",
		"enter_bank_name": "은행 이름 입력",
		"bank_account_number": "은행 계좌 번호",
		"enter_bank_account_number": "은행 계좌 번호 입력",
		"bank_code": "은행 코드",
		"enter_bank_code": "은행 코드 입력",
		"withdraw": "출금",
		"transaction_history": "트랜잭션 히스토리",
		"all_transaction": "전체 트랜잭션",
		"deposit_history": "입금 히스토리",
		"cash_out_history": "출금 히스토리",
		"buy_hours_history": "시간 구매 히스토리",
		"Date_Time": "날짜와 시간",
		"refrerence_no": "참조 번호",
		"type": "타입",
		"status": "상태",
		"amount": "금액",
		"reedem_code": "코드 사용",
		"hours": "시간",
		"buy_now": "지금 구매",
		"buy_hours": "시간 구매",
		"starter": "스타터",
		"booster": "부스터",
		"bronze": "브론즈",
		"silver": "실버",
		"gold": "골드",
		"platinum": "플래티넘",
		"titanium": "티타늄",
		"diamond": "다이아몬드",
		"redeem_code": "코드 교환",
		"promotional_or_bonus_code": "프로모션 또는 보너스",
		"enter_bonus_code": "보너스 코드 입력",
		"redeem_code": "코드 교환",
		"locked_bonus": "잠긴 보너스",
		"sr_no": "일련번호",
		"code": "코드",
		"created_date": "생성일",
		"bonus_amount": "보너스 금액",
		"expiry_date": "만료일",
		"status": "상태",
		"claim": "신청",
		"join": "참가",
		"joining": "참여중",
		"available_chips": "가용 칩",
		"table_chat": "테이블 채팅",
		"enter_your_message": "메시지 입력",
		"available_date": "가용일",
		"save": "저장",
		"enter_room_password": "패스워드를 입력해주세요",
		"close": "닫기",
		"cancle": "취소",
		"Insufficient Chips": "칩 추가",
		"You have Insufficient Chips For Play Game. Please Add More Chips To Continue.": "게임 플레이에 필요한 칩이 부족합니다. 계속하려면 칩을 더 추가하세요.",
		"Oops You are Blocked,please Contact Administrator.": "관리자에게 문의하세요",
		"table_theme": "테이블테마",
		"table_setting": "테이블 설정",
		"felt_color": "테이블 색",
		"background": "배경 색",
		"front_deck": "카드 앞면",
		"back_deck": "카드 뒷면",
		"wait": "대기",
		"add_chips": "칩 추가"
	},
	'en':{
		name: 'B2C Poker!!!!'
	}    
    
}
